import React from 'react'
import CallToAction from '../../shared/CallToActionButton'
import ButtonLink from '../../shared/ButtonLink'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import TrustpilotItem from 'src/components/shared/TrusPilotItem'
import useScrollPosition from 'src/common/hooks/useScrollPosition'
import { ConsentBanner } from 'src/components/shared/ConsentBanner'
import { useAnyElementInViewport } from 'src/common/hooks/useAnyElementInViewport'
import { Logo } from '../ActualHeader'
import { useFeatureFlags } from 'src/common/ab-testing/context'
import { featureToggles } from 'src/common/constants/featureToggles'
import { experimentSectionByVariation } from 'src/common/analytics/consts'

const LoadTrustpilotItem = () => (
  <div className="flex w-full items-center justify-center bg-green-40 py-1 text-center text-14 font-[500] lg:text-16">
    <TrustpilotItem
      starClassName="w-5.5 h-5.5"
      trustpilotRatingClassName="text-base whitespace-nowrap text-ellipsis overflow-hidden"
      starsGroupClassName="w-20 h-5 mx-2"
      className="my-0 overflow-hidden text-ellipsis"
      shortText
      detailsText
      disableRedirect
      starsGroupImgClassName="pt-1"
    />
  </div>
)

const StickyButtons = ({
  handleButtonClick,
  isArticleOrBlogPage = false,
  phoneNumberText = '',
  isHome = false,
}) => {
  const scrollPosition = useScrollPosition()
  const onViewPort = useAnyElementInViewport([
    'hero-banner-content-cta',
    'heroPrimaryBtnContent',
    'apply-url',
    'title-image',
    'heroPrimaryBtnContent',
    'hero-consumer-concept-cta',
    'hero-slider-cta',
    'Continue_Lead_CTA',
    'link-button_PromotingFinancialHealthAndAdvocacy',
    'keyTakeaways',
    'table-of-contents',
    'learn-how-it-works-facts-page-cta',
  ])
  const { getExperimentVariation } = useFeatureFlags()
  const homepageDropDownFloatingFooter = getExperimentVariation(
    experimentSectionByVariation[
      featureToggles.HOMEPAGE_DROP_DOWN_FLOATING_FOOTER
    ]
  )

  const showDropDownFloatingFooter =
    (isHome && homepageDropDownFloatingFooter === 'test') || !isHome

  return (
    <>
      {/* Sticky header */}
      {!onViewPort && showDropDownFloatingFooter && scrollPosition > 150 && (
        <div
          className={classNames(
            'fixed left-0 top-0 z-80 hidden w-full flex-col items-center justify-center bg-white drop-shadow-md duration-300 lg:flex'
          )}
        >
          <div className="flex items-center justify-center">
            <Logo />
            <div className="flex items-center justify-center">
              <p className="ml-5.5 mr-5 mt-[-10px] text-14 text-gray-255">
                Get credit card debt relief from America’s top debt relief
                company.
              </p>
              <div className=" flex flex-col items-center">
                <div className="mt-5 flex">
                  <ButtonLink
                    className="mr-5 rounded-lg border-2 border-blue-base px-5 py-2 text-16 font-bold text-blue-base"
                    href={`tel:${phoneNumberText}`}
                    onClick={(href) =>
                      handleButtonClick?.(
                        href,
                        `Call ${phoneNumberText}`,
                        'Floating Menu',
                        false
                      )
                    }
                  >
                    Call {phoneNumberText}
                  </ButtonLink>
                  <CallToAction
                    text="See if you qualify"
                    bgColor="bg-red-850"
                    className="max-w-max rounded-lg px-5 py-2.25"
                    fontClassName="font-bold text-white"
                    nav_link_section="Floating Menu"
                  />
                </div>
                <TrustpilotItem
                  starClassName="w-5.5 h-5.5"
                  trustScoreClassName="text-base"
                  trustpilotClassName="text-base"
                  trustpilotRatingClassName="text-base"
                  starsGroupClassName="w-20 h-5 mx-2.5"
                  className="my-2"
                  shortText
                  disableRedirect
                  detailsText
                  starsGroupImgClassName="pt-1"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Sticky footer */}
      <div className="fixed inset-x-0 bottom-0 z-100 lg:hidden">
        {!isArticleOrBlogPage && <ConsentBanner />}
        {!onViewPort && showDropDownFloatingFooter && (
          <div className="flex w-full flex-col justify-center bg-white drop-shadow">
            <div className="flex items-center justify-between gap-3 px-3 py-2">
              <ButtonLink
                className="whitespace-nowrap rounded-lg border-2 border-blue-base px-6 py-3 text-16 font-bold text-blue-base"
                href={`tel:${phoneNumberText}`}
                onClick={(href) =>
                  handleButtonClick?.(href, 'Call Us', 'Floating Menu', false)
                }
              >
                <span className="whitespace-nowrap">Call Us</span>
              </ButtonLink>
              <CallToAction
                text="See if you qualify"
                bgColor="bg-red-850"
                className="px-6"
                fontClassName="font-bold text-white"
                nav_link_section="Floating Menu"
              />
            </div>
            <LoadTrustpilotItem />
          </div>
        )}
      </div>
    </>
  )
}

StickyButtons.propTypes = {
  handleButtonClick: PropTypes.func,
  isArticleOrBlogPage: PropTypes.bool,
  phoneNumberText: PropTypes.string,
  isHome: PropTypes.bool,
}
export default StickyButtons
