import React, { useState, useCallback, useEffect, useMemo } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { renderable } from 'src/common/propTypes'
import styles from './index.module.scss'
import { APPLY_FDR_URL, APP_BASE_PATH } from '../../../../common/constants'
import Img from '../../../shared/Img'
import { useAnalytics } from '../../../../common/analytics'
import ButtonLink from '../../../shared/ButtonLink'
import { getDashboardPath } from '../../../../common/dashboard/consts'
import useDeviceDetect from '../../../../common/hooks/useDeviceDetect'
import Hyperlink from '../../../shared/HyperLink'
import { useLeadId } from '../../../../common/lead-id'
import { useRouter } from 'next/router'
import { buildQueryString } from '../../../../common/utils'

export const NavItem = ({
  onClick,
  selected,
  label,
  children,
  url,
  sub,
  type,
  sideBarOpen,
  id,
}) => {
  const active = selected ? styles.active : ''
  const isLabel = type === 'title' ? `${styles.isLabel} font-extrabold` : ''
  const { isMobile } = useDeviceDetect()

  return sub ? (
    <li
      className={classNames(styles.menuItem, active, isLabel, 'relative')}
      role="menuitem"
      onClick={onClick}
      onKeyPress={onClick}
      id={id || label}
    >
      {!url && <span className="cursor-default p-4 lg:p-0">{label}</span>}
      {url && (
        <Hyperlink
          href={url}
          prefetch={false}
          itemProp="url"
          childrenClassNames="p-4 lg:p-0 text-14 text-gray-255"
          clickTracking={false}
        >
          {label}
        </Hyperlink>
      )}
      {children}
    </li>
  ) : (
    <li
      className={classNames(styles.menuItem, active, 'relative')}
      onClick={onClick}
      onKeyPress={onClick}
      role="menuitem"
      id={label}
    >
      {!url && (
        <div className="flex flex-row items-center justify-between">
          <span
            className={`${
              !url ? 'whitespace-nowrap p-4 lg:px-2 lg:py-3' : 'p-0'
            } cursor-default`}
          >
            {label}
          </span>
          {sideBarOpen && isMobile ? (
            <Img
              src="/next-assets/icons/navbar-arrow.svg"
              className="mr-4 w-3"
              imgClassName={`w-3 static ${selected ? 'rotate-180' : ''}`}
              alt="navbar-arrow"
            />
          ) : null}
        </div>
      )}
      {url && (
        <Hyperlink
          href={url}
          itemProp="url"
          childrenClassNames="p-4 lg:p-0 text-14 text-gray-255"
          clickTracking={false}
          prefetch={false}
        >
          {label}
        </Hyperlink>
      )}
      {children}
    </li>
  )
}

NavItem.defaultProps = {
  onClick: () => {},
  selected: false,
  sub: false,
  label: '',
  children: [],
  url: '',
  type: '',
}
NavItem.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  sub: PropTypes.bool,
  label: PropTypes.string,
  children: renderable,
  url: PropTypes.string,
  type: PropTypes.string,
  sideBarOpen: PropTypes.bool,
}

const NavBar = ({
  menuData,
  sideBarOpen,
  setSideBarOpen,
  locale,
  btnClassName,
  dashboardClass,
  statusCode,
  showButtonOptions,
}) => {
  const [selected, setSelected] = useState('unselected')
  const { track } = useAnalytics()
  const [dashboardLink, setDashboardLink] = useState('/')
  const leadId = useLeadId()
  const { query: baseQuery } = useRouter()

  let query = useMemo(
    () => buildQueryString(baseQuery, leadId),
    [baseQuery, leadId]
  )
  useEffect(() => {
    const host = window.location.hostname
    const DASHBOARD_LINK_PATH = getDashboardPath(host)
    setDashboardLink(DASHBOARD_LINK_PATH)
  }, [])

  const handleButtonClick = useCallback(
    //todo changed as CTA button, but destination url is https://fdr-dev.programdashboard.com/login
    (href, name, isLeadCta) => {
      const data = {
        nav_link_section: 'Header',
        click_type: 'Button Click',
        click_id: name,
        click_url: href,
        click_text: `FDR-Web | ${name}`,
        track_event: 'global_header',
      }
      track(
        data,
        {
          variation: null,
          event_type: isLeadCta ? 'conversion' : 'track',
        },
        'click',
        statusCode
      )
    },
    [statusCode, track]
  )

  const onNavItemClick = useCallback(
    (e, id) => {
      const itemName = e.target.innerText

      if (id === selected) {
        setSelected('unselected')
      } else {
        setSelected(id)
      }

      if (id === 'nav-item-espanol') {
        const data = {
          nav_link_section: 'Mega Menu',
          mega_menu_tab: itemName,
          mega_menu_link: itemName,
          track_event: 'mega_menu',
          event_type: 'track',
          event_action: 'click',
          click_text: `FDR-Web | ${itemName}`,
        }
        track(data, {}, 'click', statusCode)
      }
    },
    [selected, track, statusCode]
  )

  const handleDropdownLinkClick = useCallback(
    (e, itemName, url, subItemName) => {
      const data = {
        nav_link_section: 'Mega Menu',
        mega_menu_tab: itemName,
        mega_menu_link: subItemName,
        track_event: 'mega_menu',
        event_type: 'track',
        event_action: 'click',
        click_text: `FDR-Web | ${subItemName}`,
      }
      track(data, {}, 'click', statusCode)
      setSideBarOpen(false)
    },
    [track, statusCode]
  )

  const handlePhoneClick = useCallback(
    (event, clickText, nav_link_section) => {
      const { href, baseURI } = event.target
      const phoneNumber = href.slice(4)
      const data = {
        click_id: clickText,
        click_text: `FDR-Web | ${clickText}`,
        click_type: 'Phone Click',
        tealium_event: 'link_click',
        event_origin: 'client',
        event_type: 'click',
        event_category: 'cta_tracking',
        event_action: 'phone-click',
        event_label: `${phoneNumber} - ${baseURI}`,
        nav_link_section,
      }
      track(data, {}, 'click', statusCode)
    },
    [track, statusCode]
  )

  return (
    <nav
      className={`${styles.mainNav} text-gray-700 z-20 text-center lg:flex lg:justify-between xl:w-full`}
      id="nav"
    >
      <div className="relative z-20 h-full-vh bg-white lg:h-auto">
        <div
          className={`fixed top-0 h-full w-62 overflow-y-auto lg:static lg:flex lg:h-auto lg:w-full lg:overflow-y-visible`}
        >
          <div className="bg-indigo p-3 lg:hidden">
            <Img
              src="/next-assets/icons/fdr-logo-white.svg"
              className="h-10 w-20"
              alt="fdr logo"
              priority={true}
              styles={{ position: 'relative', height: '40px', width: '80px' }}
              layout="responsive"
            />
          </div>
          <ul
            itemType="https://www.schema.org/SiteNavigationElement"
            className={`${styles.primaryMenu} lg:flex lg:items-center lg:px-4`}
            role="menubar"
            aria-label="Primary Menu"
          >
            {menuData.map(({ id, name, url, subItems }) => (
              <NavItem
                key={`primary-${id}`}
                label={
                  locale === 'es' && id === 'nav-item-espanol'
                    ? 'English'
                    : name
                }
                url={locale === 'es' && id === 'nav-item-espanol' ? '/' : url}
                selected={selected === id}
                onClick={(e) => onNavItemClick(e, id, url)}
                sideBarOpen={sideBarOpen}
              >
                {Array.isArray(subItems) ? (
                  <ul className={styles.subMenu}>
                    {subItems.map((subItem) => (
                      <NavItem
                        key={`sub-${subItem.id}`}
                        label={subItem.name}
                        id={`${id}-${subItem.name
                          .toLowerCase()
                          .replaceAll(' ', '-')}`}
                        url={subItem.url}
                        type={subItem.type}
                        sub
                        onClick={(e) =>
                          handleDropdownLinkClick(
                            e,
                            name,
                            subItem.url,
                            subItem.name
                          )
                        }
                      />
                    ))}
                  </ul>
                ) : null}
              </NavItem>
            ))}
          </ul>

          {showButtonOptions && (
            <div className={`py-4 text-center lg:flex`}>
              <a
                href={`${APP_BASE_PATH()}/login`}
                rel="noopener"
                target="_blank"
                onClick={(e) =>
                  handleButtonClick(e.currentTarget.href, 'Client Dashboard')
                }
                className={`rounded-lg bg-blue-540 px-3 py-1.5  text-13 font-bold text-blue-base ${dashboardClass}`}
              >
                Client Dashboard
              </a>
              <ButtonLink
                href={`${APPLY_FDR_URL()}?${query}`}
                target="_blank"
                rel="noopener noreferrer"
                className={`mt-4 rounded-lg border-2 border-blue-base bg-white px-2.5 py-1 text-13 font-bold text-blue-base lg:ml-4 lg:mt-0 ${btnClassName}`}
                onClick={(href) =>
                  handleButtonClick(href, 'See if you qualify', true)
                }
              >
                See if you qualify
              </ButtonLink>
            </div>
          )}
          <div className="px-2 py-4 text-center lg:hidden">
            <p>
              Talk to a certified debt consultant
              <a
                href="tel:800-910-0065"
                className="text-semibold block text-base"
                onClick={(e) =>
                  handlePhoneClick(e, '800-910-0065', 'Mobile menu text')
                }
              >
                800-910-0065
              </a>
            </p>
            <a
              href="tel:800-910-0065"
              className="mx-auto mt-4 block w-36 bg-blue-300 px-4 py-2 text-white lg:hidden"
              onClick={(e) =>
                handlePhoneClick(e, 'Call Us', 'Mobile menu button')
              }
            >
              Call Us
            </a>
          </div>
        </div>
      </div>
    </nav>
  )
}

NavBar.defaultProps = {
  menuData: [],
  locale: 'en',
  btnClassName: '',
  dashboardClass: '',
}
NavBar.propTypes = {
  menuData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      subItems: PropTypes.array,
    })
  ),
  sideBarOpen: PropTypes.bool,
  locale: PropTypes.string,
  setSideBarOpen: PropTypes.func,
  btnClassName: PropTypes.string,
  dashboardClass: PropTypes.string,
  statusCode: PropTypes.number,
}

export default NavBar
