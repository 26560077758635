import React, { useCallback, useMemo, useRef } from 'react'
import { useRouter } from 'next/router'
import NextLink from 'next/link'
import PropTypes from 'prop-types'
import menuData from 'src/components/layouts/ActualHeader/menuData'
import styles from './index.module.scss'
import NavBar from './NavBar'
import Img from '../../shared/Img'
import { useAnalytics } from '../../../common/analytics'
import PhoneNumber from '../../shared/PhoneNumber'
import { APPLY_FDR_URL } from '../../../common/constants'
import { useLeadId } from '../../../common/lead-id'
import ButtonLink from '../../shared/ButtonLink'
import { buildQueryString, getPhoneNumber } from '../../../common/utils'

import StickyButtons from '../StickyButtons'
import useScrollPosition from 'src/common/hooks/useScrollPosition'
import classNames from 'classnames'
import { ConsentBanner } from 'src/components/shared/ConsentBanner'

import { useFeatureFlags } from 'src/common/ab-testing/context'
import { featureToggles } from 'src/common/constants/featureToggles'
import { experimentSectionByVariation } from 'src/common/analytics/consts'

import { allowedRouteList } from 'src/common/allowed-route-list'

export const Logo = () => {
  const { track } = useAnalytics()

  const handleLogoClick = useCallback(
    (event) => {
      const data = {
        nav_link_section: 'Header',
        click_type: 'Logo Click',
        click_id: 'Freedom Debt Relief',
        click_url: '/',
        click_text: 'FDR-Web | Freedom Debt Relief',
        track_event: 'global_header',
        event_type: 'track',
      }

      track(data, {}, 'click')
      if (allowedRouteList.includes('/')) {
        event.preventDefault()
        window.location.href = window.location.origin + '/'
      }
    },
    [track]
  )

  return (
    <NextLink
      href="/"
      prefetch={false}
      shallow={true}
      data-testid="logo-link"
      onClick={handleLogoClick}
    >
      <div>
        <Img
          priority={true}
          dataTestId="fdr-logo"
          src="/next-assets/icons/fdr-logo.svg"
          alt="Freedom Debt Relief"
          className="h-12 w-28"
          layout="responsive"
          styles={{
            position: 'relative',
            height: '3rem',
            width: '7rem',
          }}
        />
      </div>
    </NextLink>
  )
}

const Header = ({
  sideBarOpen,
  setSideBarOpen,
  withHeaderTel,
  locale,
  btnClassName,
  statusCode,
  isArticleOrBlogPage = false,
  isBrandPage = false,
  isHome,
  isSalesPage,
  handleSideNavClick,
}) => {
  const { track } = useAnalytics()

  const leadId = useLeadId()
  const scrollPosition = useScrollPosition()

  const { getExperimentVariation } = useFeatureFlags()
  const homepageDropDownFloatingFooter = getExperimentVariation(
    experimentSectionByVariation[
      featureToggles.HOMEPAGE_DROP_DOWN_FLOATING_FOOTER
    ]
  )

  const { query: baseQuery } = useRouter()
  const flotingPhoneNumber = getPhoneNumber(
    isHome,
    homepageDropDownFloatingFooter
  )
  let query = useMemo(
    () => buildQueryString(baseQuery, leadId),
    [baseQuery, leadId]
  )
  const headerBannerText = sideBarOpen ? 'hidden' : 'hidden md:block lg:p-1'

  const handleButtonClick = useCallback(
    //todo changed as CTA button, but destination url is https://fdr-dev.programdashboard.com/login
    (href, name, section, btn = true, callUs = false) => {
      const data = {
        nav_link_section: section,
        click_type: btn ? 'Button Click' : 'Phone Click',
        click_id: name,
        click_url: href,
        click_text: `FDR-Web | ${name}`,
        track_event: 'global_header',
      }
      if (callUs) {
        track(
          data,
          {
            experimentSection: 'clientLogin',
            event_type: 'start',
            variation: 'control',
          },
          'click',
          statusCode
        )
      } else {
        track(data, {}, 'click', statusCode)
      }
    },
    [track, statusCode]
  )

  const headerRef = useRef()

  return (
    <>
      <div className="mb-0 lg:mb-24" />
      <header
        ref={headerRef}
        className={classNames(
          'fixed top-0 z-100 flex w-full justify-start bg-white duration-300 lg:static lg:justify-center'
        )}
        data-testid="app-header"
        id="app-header"
      >
        {/* Desktop */}
        <div className="gap-x-primary col-span-full hidden grid-cols-4 py-2 text-ms text-gray-510 lg:grid lg:grid-cols-12 lg:py-0">
          <div className="col-span-full col-start-1 m-auto lg:col-span-12">
            <div className={`${styles.wrapper} flex`}>
              <Logo />
              <NavBar
                menuData={menuData}
                sideBarOpen={sideBarOpen}
                setSideBarOpen={setSideBarOpen}
                locale={locale}
                btnClassName={btnClassName}
                statusCode={statusCode}
                showButtonOptions
              />
            </div>
          </div>
          <div
            className={`col-span-3 hidden pr-2 lg:order-first lg:col-span-12 lg:block`}
          >
            {withHeaderTel && (
              <div
                className={headerBannerText}
                data-testid="header-banner-text"
              >
                <p className="text-right">
                  <span className="text-xs text-black-light">
                    Talk to a certified debt consultant
                  </span>
                  <PhoneNumber
                    href={`tel:${flotingPhoneNumber}`}
                    className="ml-2 text-base font-semibold text-black-light no-underline"
                    text={flotingPhoneNumber}
                    isTrack={true}
                    statusCode={statusCode}
                    nav_link_section="Above header"
                  />
                </p>
              </div>
            )}
          </div>
          <div className="fixed left-0 bottom-0 w-full">
            <ConsentBanner />
          </div>
        </div>

        {/* Mobile */}
        <div className="block w-full lg:hidden">
          {scrollPosition < 150 && (
            <div
              className={`mx-2 flex flex-equal items-center justify-between py-2`}
            >
              <NavBar
                menuData={menuData}
                sideBarOpen={sideBarOpen}
                setSideBarOpen={setSideBarOpen}
                locale={locale}
                btnClassName={btnClassName}
                statusCode={statusCode}
                showButtonOptions
              />
              <button
                type="button"
                onClick={handleSideNavClick}
                className="text-blue-300  focus:outline-none"
                aria-label="menu toggle"
              >
                <svg className="fill-current h-6 w-6" viewBox="0 0 24 24">
                  {/* Show X button */}
                  {sideBarOpen ? (
                    <path
                      fillRule="evenodd"
                      d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                    />
                  ) : (
                    <path
                      fillRule="evenodd"
                      d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                    />
                  )}
                </svg>
              </button>
              <Logo />
              <ButtonLink
                href={`tel:${flotingPhoneNumber}`}
                className="z-10 mr-3 whitespace-nowrap rounded-lg bg-blue-540 px-2.5 py-1.5 text-13 font-bold leading-22 text-blue-base"
                onClick={(href) =>
                  handleButtonClick(
                    href,
                    'Call Us',
                    'Mobile header',
                    false,
                    true
                  )
                }
              >
                Call Us
              </ButtonLink>
              <ButtonLink
                href={`${APPLY_FDR_URL()}?${query}`}
                target="_blank"
                rel="noopener noreferrer"
                className={`whitespace-nowrap rounded-lg border-2 border-blue-base bg-white px-2.5 py-1 text-13 font-bold text-blue-base lg:ml-4 ${btnClassName}`}
                onClick={(href) =>
                  handleButtonClick(href, 'See if you qualify', 'Header')
                }
              >
                See if you qualify
              </ButtonLink>
            </div>
          )}
          {isArticleOrBlogPage && !isBrandPage && <ConsentBanner />}
        </div>
      </header>
      {isSalesPage ? null : (
        <StickyButtons
          handleButtonClick={handleButtonClick}
          phoneNumberText={flotingPhoneNumber}
          isArticleOrBlogPage={isArticleOrBlogPage}
          isHome={isHome}
        />
      )}
    </>
  )
}

Header.defaultProps = {
  sideBarOpen: false,
  withHeaderTel: true,
  locale: 'en',
  btnClassName: undefined,
  isHome: false,
}

Header.propTypes = {
  sideBarOpen: PropTypes.bool,
  withHeaderTel: PropTypes.bool,
  locale: PropTypes.string,
  setSideBarOpen: PropTypes.func,
  btnClassName: PropTypes.string,
  statusCode: PropTypes.number,
  isHome: PropTypes.bool,
  isSalesPage: PropTypes.bool,
}

export default Header
