import { useEffect, useState } from 'react'

export const useAnyElementInViewport = (ids) => {
  const [isAnyElementInViewport, setIsAnyElementInViewport] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const anyInViewport = entries.some((entry) => entry.isIntersecting)
        setIsAnyElementInViewport(anyInViewport)
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0,
      }
    )

    ids.forEach((id) => {
      const element = document.getElementById(id)
      if (element) {
        observer.observe(element)
      }
    })

    return () => {
      ids.forEach((id) => {
        const element = document.getElementById(id)
        if (element) {
          observer.unobserve(element)
        }
      })
    }
  }, [ids])

  return isAnyElementInViewport
}
