import classNames from 'classnames'
import PropTypes from 'prop-types'

export const AnimatedArrowRedCTA = ({
  buttonText,
  onClick,
  url,
  className = '',
  id,
}) => {
  return (
    <a
      id={id}
      onClick={onClick}
      href={url}
      className={classNames(
        'group flex w-fit items-center justify-center gap-x-3 rounded-lg bg-red-850 px-3 py-3',
        className
      )}
    >
      <span className="whitespace-nowrap text-lg font-bold text-white">
        {buttonText}
      </span>
      <svg
        width="14"
        height="14"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="stroke-white stroke-2"
      >
        <g className="-translate-x-2 transition-transform duration-100 ease-in-out group-hover:translate-x-0">
          <path d="M11.5 22L21.5 12L11.5 2" strokeWidth="4" />
          <line
            className="opacity-0 transition-opacity duration-75 ease-in-out group-hover:opacity-100"
            x1="21"
            y1="12"
            x2="1"
            y2="12"
            strokeWidth="4"
          />
        </g>
      </svg>
    </a>
  )
}

AnimatedArrowRedCTA.defaultProps = {
  buttonText: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default AnimatedArrowRedCTA
